import getConfig from 'next/config';
import { useMemo } from 'react';

import { Drawer, type DrawerProps } from '@src/ui/material-ui';

import { useIsMobile } from '@src/hooks/use-is-mobile';
import { white } from '@src/support/colors';

const { NODE_ENV } = getConfig().publicRuntimeConfig;

type Props = { wide?: boolean } & DrawerProps;

export const ResponsiveDrawer = ({ wide, ...drawerProps }: Props) => {
  const isMobile = useIsMobile();

  const effectiveTransitionDuration = useMemo(() => {
    // Make transitions instant when running in test environments, to save ~300ms per panel action
    if (NODE_ENV === 'test') {
      return 0;
    }

    if (isMobile) {
      // Make back gesture on safari mobile less bad
      return drawerProps.open ? undefined : 0;
    }

    return undefined;
  }, [drawerProps.open, isMobile]);

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: {
          width: { xs: '100%', sm: wide ? '43.25rem' : '35.5rem' },
          backgroundColor: white,
        },
      }}
      transitionDuration={effectiveTransitionDuration}
      {...drawerProps}
    />
  );
};
