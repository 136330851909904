import {
  DESKTOP_MIN_WIDTH_PX_VALUE,
  MATCH_DETAIL_INSIGHTS_BREAKPOINT,
} from '@src/constants';
import { rgba } from '@src/support/rgba';
import { boxShadow } from '@src/support/shadows';
import { type Theme, createTheme } from '@src/ui/material-ui';
import { Inter } from 'next/font/google';
import * as colors from './colors';

const inter = Inter({ subsets: ['latin'] });

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: DESKTOP_MIN_WIDTH_PX_VALUE,
      md: DESKTOP_MIN_WIDTH_PX_VALUE,
      lg: MATCH_DETAIL_INSIGHTS_BREAKPOINT,
      xl: MATCH_DETAIL_INSIGHTS_BREAKPOINT,
    },
  },
  typography: {
    fontFamily: inter.style.fontFamily,
  },
  palette: {
    primary: {
      main: colors.neutralHighest1,
      light: colors.neutralHighest,
      dark: colors.primary01,
    },
    secondary: {
      main: colors.semanticError,
      light: colors.semanticErrorLow,
      dark: colors.semanticErrorHigh,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          outline: undefined,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          display: 'inline',
          verticalAlign: 'baseline',
        },
        badge: {
          paddingTop: '0',
          paddingRight: '0.25rem',
          paddingBottom: '0',
          paddingLeft: '0.25rem',
          borderRadius: '0.875rem',
          fontSize: '0.625rem',
          height: '1.75rem',
          minWidth: '1.75rem',
          padding: undefined,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: colors.neutralHighest1,
          backgroundColor: colors.white,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: `${rgba(colors.black, 0.15)} 0 0 0.5rem 0`,
        },
        elevation4: {
          boxShadow: `${boxShadow}`,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colors.neutralHighest1,
          fontSize: '1rem',
          marginRight: 0,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: colors.semanticSuccess,
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          color: colors.neutralHighest,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: colors.neutralHighest,
          display: 'inline-block',
          fontSize: '0.875rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.8125rem',
          fontStyle: 'italic',
          lineHeight: '1rem',
          color: colors.neutralHighest,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: colors.neutralHighest,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.neutralHighest,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.neutralHighest,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          position: 'static',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
        label: {
          color: colors.neutralHighest1,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          marginTop: '0.75rem',
          marginBottom: '0.75rem',
        },
        formControl: {
          'label + &': {
            marginTop: 0,
          },
        },
        underline: {
          '&:after, &:hover:not(.Mui-disabled):before': {
            borderBottom: `2px solid ${colors.neutralHighest1}`,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: '1.5rem',
          whiteSpace: 'wrap',
        },
        formControl: {
          position: 'relative',
          left: undefined,
          top: undefined,
          transform: 'translate(0, 1.5rem) scale(1)',
        },
        shrink: {
          transform: 'translate(0, 1.5px) scale(0.75)',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: '1.5rem',
          textAlign: 'left',
        },
      },
    },
  },
});
