import type { NavigationProfileDropdownUserFragment } from '@generated/graphql';
import { NavigationProfileDropdownLink } from '@src/components/navigation-profile-dropdown/navigation-profile-dropdown-link';
import { ReferLink } from '@src/components/refer-link';
import { SEGMENT_EVENTS } from '@src/constants';
import { NAV_Z_INDEX } from '@src/constants/nav';
import * as colors from '@src/support/colors';
import { Button } from '@src/ui';
import { Popover } from '@src/ui/popover';
import { userDisplayName } from '@src/utils/user-utils';
import type { Ref, RefObject } from 'react';
import { useTrackNavigationClick } from '../navigation/use-track-navigation-click';

type Props = {
  open?: boolean;
  anchorRef?: RefObject<HTMLDivElement | null>;
  user: NavigationProfileDropdownUserFragment;
  reference?: Ref<HTMLDivElement>;
  onClose: () => void;
  clinicianNotNew: boolean;
  referText?: string | null;
};

export function NavigationProfileDropdown({
  user,
  open,
  anchorRef,
  reference,
  onClose,
  clinicianNotNew = true,
  referText,
}: Props) {
  const trackNavigationClick = useTrackNavigationClick();

  return (
    <Popover
      shadow="bottom"
      shape="rounded"
      open={open}
      anchorRef={anchorRef}
      offsetX={0}
      offsetY={15}
      animate="slideDown"
      placement="bottom-start"
      onClose={onClose}
      zIndex={NAV_Z_INDEX}
    >
      <div className="NavigationProfileDropdown" ref={reference}>
        <div className="NavigationProfileDropdownHeader">
          <img
            className="NavigationProfileDropdownImg"
            src={user.profilePic ?? '/static/profile/empty-flo.png'}
            width={40}
            height={40}
            alt="Profile Photo"
          />
          <div className="NavigationProfileDropdownUserInfo">
            <div className="NavigationProfileDropdownHeaderName">
              {userDisplayName(user, { abbreviateLast: true })}
            </div>
            <div className="NavigationProfileDropdownHeaderRole">
              {user.nurseProfile?.role?.name ?? ''}
            </div>
          </div>
        </div>
        <hr />

        {referText && (
          <div className="NavigationProfileDropdownReferLink">
            <ReferLink
              text={referText}
              onClick={() => {
                trackNavigationClick(
                  SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_INVITE_LINK,
                  'dropdown'
                );
                onClose();
              }}
            />
          </div>
        )}

        <NavigationProfileDropdownLink
          href="/account"
          label="Account"
          onClick={() =>
            trackNavigationClick(
              SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_ACCOUNT_LINK,
              'dropdown'
            )
          }
        />

        {clinicianNotNew && (
          <NavigationProfileDropdownLink
            href="/explorer"
            label="Explorer"
            onClick={() =>
              trackNavigationClick(
                SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_EXPLORER_LINK,
                'dropdown'
              )
            }
          />
        )}

        {clinicianNotNew && (
          <NavigationProfileDropdownLink
            href="https://www.facebook.com/groups/thetrustedcircle"
            label="Community"
            onClick={() =>
              trackNavigationClick(
                SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_COMMUNITY_LINK,
                'dropdown'
              )
            }
          />
        )}

        {clinicianNotNew && (
          <NavigationProfileDropdownLink
            href="https://help.trustedhealth.com"
            label="Help Center"
            onClick={() =>
              trackNavigationClick(
                SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_HELP_CENTER_LINK,
                'dropdown'
              )
            }
          />
        )}

        <Button
          style={{
            padding: 0,
            borderWidth: 0,
            lineHeight: '1rem',
            textUnderlineOffset: '.2rem',
          }}
          component="a"
          size="medium"
          variant="hollowWhite"
          border="transparentOnHover"
          underline
          href="/logout"
          onClick={() =>
            trackNavigationClick(
              SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_LOGOUT_LINK,
              'dropdown'
            )
          }
        >
          Log Out
        </Button>
      </div>
      <style jsx>{`
        .NavigationProfileDropdown {
          max-width: 22.375rem;
          min-width: 20rem;
          padding: 1.5rem 1rem;
        }

        hr {
          height: 1px;
          background-color: ${colors.neutralDefault};
          margin: 1rem 0;
        }

        .NavigationProfileDropdownReferLink {
          padding-bottom: 1.5rem;
        }

        .NavigationProfileDropdownHeader {
          display: flex;
          flex-direction: row;
          margin-bottom: 0.75rem;
        }

        .NavigationProfileDropdownImg {
          border-radius: 2.5rem;
          border: 1px solid ${colors.black};
          overflow: hidden;
          object-fit: cover;
          margin-right: 0.5rem;
        }

        .NavigationProfileDropdownUserInfo {
          flex-grow: 1;
        }

        .NavigationProfileDropdownHeaderName {
          font-size: 1rem;
          font-weight: 700;
        }

        .NavigationProfileDropdownHeaderRole {
          font-size: 0.875rem;
          color: ${colors.neutralHighest};
        }

        .NavigationProfileDropdownProfileCompletion {
          border: ${colors.neutralLow} 1px solid;
          border-radius: 0.5rem;
          padding: 1rem;
          margin-bottom: 1rem;
          cursor: pointer;
        }
      `}</style>
    </Popover>
  );
}
