import { MOBILE_MAX_WIDTH } from '@src/constants';
import { NAV_Z_INDEX, SIDEBAR_SIZE } from '@src/constants/nav';
import type { ReactNode } from 'react';

export const NavSidebar = (props: { children?: ReactNode }) => (
  <div className="NavSidebar">
    {props.children}
    <style jsx>{`
      .NavSidebar {
        bottom: 0;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;
        width: ${SIDEBAR_SIZE};
        z-index: ${NAV_Z_INDEX};
        transition: background-color 0.3s ease-in-out;
      }

      @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
        .NavSidebar {
          display: none;
        }
      }
    `}</style>
  </div>
);
